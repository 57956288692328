import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid2";



export default function PrimaryFooter({ toogleSidebar }) {
    return (
        <Box component="main" sx={{ flexGrow: 1, width: '100%', padding: '50px', backgroundColor: '#010002' }}>
            <Grid container spacing={2}>
                <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                </Grid>
                <Grid display="flex" justifyContent="right" alignItems="center" sx={{ width: '100px', height: '50px' }}>
                    <Box component="img" alt='X1k Image' sx={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/x1k_logo.png`} />
                </Grid>
                <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: '25px' }}>
                <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                </Grid>
                <Grid display="flex" justifyContent="center" alignItems="center" container spacing={3.5}>
                    <a href="https://warpcast.com/x1kfunded" target="_blank" rel="noopener noreferrer">
                        <Box component="img" alt='OBJECTS Image' src={`${process.env.PUBLIC_URL}/images/OBJECTS.png`} sx={{ width: '25px', height: '20px' }} />
                    </a>
                    <a href="https://t.me/x1kfundedchannel" target="_blank" rel="noopener noreferrer">
                        <Box component="img" alt='Send Image' src={`${process.env.PUBLIC_URL}/images/Send.png`} sx={{ width: '25px', height: '20px' }} />
                    </a>
                    <a href="https://discord.gg/HCU4g8N9F5" target="_blank" rel="noopener noreferrer">
                        <Box component="img" alt='Discord Image' src={`${process.env.PUBLIC_URL}/images/Discord.png`} sx={{ width: '25px', height: '20px' }} />
                    </a>
                    <a href="https://x.com/X1Kfunded" target="_blank" rel="noopener noreferrer">
                        <Box component="img" alt='X_logo_2023 Image' src={`${process.env.PUBLIC_URL}/images/X_logo_2023.png`} sx={{ width: '25px', height: '20px' }} />
                    </a>
                </Grid>
                <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                </Grid>
            </Grid>
            <Grid container spacing={0} justifyContent="center" alignItems="center" sx={{ marginTop: '25px', textAlign: 'center' }} className='text-dark-gray'>
                <Grid size={{ xs: 12, md: 1 }}>About Us</Grid>
                <Grid size={{ xs: 12, md: 1 }}>Blog</Grid>
                <Grid size={{ xs: 12, md: 1 }}>Medium</Grid>
                {/* <Box>FAQ</Box> */}
                <Grid size={{ xs: 12, md: 3 }}>2024 CoinMarketCap. All rights reserved</Grid>
            </Grid>
        </Box>
    );
}

