/* eslint-disable jsx-a11y/alt-text */
import { Box, Toolbar, Typography, Switch } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./profile.style.css";
import BreadcrumbsNav from "../Breadcrumbs";
import React, { useState, useEffect } from "react";
import { BrowserProvider } from "ethers";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import api from "../../instance_api_key";
import { useSnackbar } from "../SnackbarProvider";
import TwitterPage from "./twitterPage";
import { useLocation } from "react-router-dom";
import api_key from "../../instance_api_key";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
};

const Profile = () => {
  //#region TWITTER RESPONSE
  const location = useLocation();
  const [userTwitter, setUserTwitter] = useState(null);

  // Lấy `twitter_id` từ URL params
  const getTwitterIdFromUrl = () => {
    const params = new URLSearchParams(location.search);
    const twitter_id = decodeTwitterId(params.get("twitter_id"));
    localStorage.setItem("encodedTwitterId", `${twitter_id}`);
    return twitter_id;
  };

  useEffect(() => {
    getTwitterIdFromUrl();
  }, []);

  const decodeTwitterId = (encodedTwitterId) => {
    try {
      const decodedTwitterId = atob(encodedTwitterId);
      return decodedTwitterId;
    } catch (error) {
      console.error("Lỗi giải mã Twitter ID:", error);
      return null;
    }
  };

  const twitterId = localStorage.getItem("encodedTwitterId");

  const getDataFindTwitterId = () => {
    api
      .get(`/api/twitter/auth/get-user-twitter-db?twitter_id=${twitterId}`)
      .then((response) => {
        if (response.data) {
          const data = response.data;
          setUserTwitter(data);
        }
        console.log(response.data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getDataFindTwitterId();
  }, []);

  //#endregion

  const showSnackbar = useSnackbar();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [walletAddress, setWalletAddress] = useState(null);
  const [email, setEmail] = useState(null);
  const [telegram, setTelegram] = useState(null);
  const [discord, setDiscord] = useState(null);
  const [linkFacaster, setLinkFacaster] = useState(null);
  const [avartar, setAvartar] = useState(null);
  const [profile, setProfile] = useState(null);
  let isEffectExecuted = false;
  useEffect(() => {
    if (!isEffectExecuted) {
      checkIfWalletIsConnected();
      isEffectExecuted = true;
    }
  }, []);

  const checkIfWalletIsConnected = async () => {
    const tempProvider = new BrowserProvider(window.ethereum);

    // Request account access if needed
    const accounts = await tempProvider.send("eth_requestAccounts", []);
    getDataProfile(accounts[0]);
    setWalletAddress(accounts[0]);
  };

  const getDataProfile = (walletAddress) => {
    api
      .get(`/api/profile/getByWalletAddress/${walletAddress}`)
      .then((response) => {
        if (response.data) {
          const dataProfile = response.data[0];
          dataProfile.wallet_address = walletAddress;
          setEmail(dataProfile.email);
          setTelegram(dataProfile.link_telegram);
          setDiscord(dataProfile.link_discord);
          // setUserName(dataProfile.user_name);
          // setLinkX(dataProfile.link_x);
          // setLinkFarcaster(dataProfile.link_facaster);
          // setAvartar(dataProfile.avartar);
          setProfile(dataProfile);
        }
        console.log(response.data);
      })
      .catch((error) => console.error(error));
  };

  const update = () => {
    api
      .post(`/api/profile/update_profile`, {
        id: profile?.id,
        email: email,
        linkX: profile.link_x,
        linkFacaster: linkFacaster,
        avartar: profile.avartar,
        link_discord: discord,
        link_telegram: telegram,
      })
      .then((response) => {
        console.log(response.data);
        handleClose();
        handleCloseTelegram();
        handleCloseDiscord();
        getDataProfile(walletAddress);
      })
      .catch((error) => console.error(error));
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openTelegram, setOpenTelegram] = React.useState(false);
  const handleOpenTelegram = () => {
    setOpenTelegram(true);
  };
  const handleCloseTelegram = () => {
    setOpenTelegram(false);
  };

  const [openDiscord, setOpenDiscord] = React.useState(false);
  const handleOpenDiscord = () => {
    setOpenDiscord(true);
  };
  const handleCloseDiscord = () => {
    setOpenDiscord(false);
  };

  const [codeRef, setCodeRef] = useState(null);
  const handleChangeCodeRef = (event) => {
    setCodeRef(event.target.value);
  };
  const handleAdd = () => {
    console.log("codeRef", codeRef);
    api
      .post(`/api/ref/post_v1_ref_insert_ref`, {
        wallet_address: walletAddress,
        code_ref: codeRef,
      })
      .then((response) => {
        if (response.data) {
          showSnackbar(response.data.messages, "success");
        }
        console.log(response.data);
      })
      .catch((error) => console.error(error));
  };

  const handleSendEmail = () => {
    

    const _email = `nhatnn2001.hanoi@gmail.com`;

    // Gọi API với method POST và gửi dữ liệu qua body
    fetch("http://localhost:3185/api/email/send-email-xk", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: _email,
        title: "Tiêu đề",
        content: ``,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Email sent successfully:", data);
        // Optionally, redirect or update the UI
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#F8F8F8" }}>
      <Toolbar />
      <BreadcrumbsNav />
      <br></br>
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
          <Typography variant="h5" gutterBottom>
            Profile
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Edit and manage your personal and account information here
          </Typography>
        </Grid>
        <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
          <div className="avartar-item">
            <div
              className={`avartar-back ${profile?.email ? "active" : "inactive"}`}
            >
              <img
                style={{ width: "120px", height: "120px", borderRadius: "50%" }}
                src={
                  profile?.avartar
                    ? profile?.avartar
                    : `${process.env.PUBLIC_URL}/images/profile-avatar.png`
                }
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Email</div>
                <div>{profile?.email}</div>
              </div>
              <div
                style={{ width: "10%", textAlign: "end", cursor: "pointer" }}
                onClick={handleOpen}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Twitter</div>

                {userTwitter?.userTwitter ? (
                  <div>{userTwitter?.userTwitter?.username}</div>
                ) : (
                  <button
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}/api/twitter/auth/twitter`,
                        "_self"
                      )
                    }
                  >
                    Login with Twitter
                  </button>
                )}
              </div>
              <div
                style={{ width: "10%", textAlign: "end", cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Telegram</div>
                <div>{profile?.link_telegram}</div>
              </div>
              <div
                style={{ width: "10%", textAlign: "end", cursor: "pointer" }}
                onClick={handleOpenTelegram}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Discord</div>
                <div>{profile?.link_discord}</div>
              </div>
              <div
                style={{ width: "10%", textAlign: "end", cursor: "pointer" }}
                onClick={handleOpenDiscord}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Link farcaster</div>
                <div>{profile?.linkFarcaster}</div>
              </div>
              <div style={{ width: "10%", textAlign: "end" }}>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  onClick={handleSendEmail}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #ececec",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Metamask Add</div>
                <div>{walletAddress}</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Wallet address"
                variant="outlined"
                size="small"
                sx={{ width: "85%" }}
                value={codeRef}
                onChange={handleChangeCodeRef}
              />
              <Button
                variant="contained"
                onClick={handleAdd}
                sx={{ width: "15%" }}
              >
                ADD REF
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
          <div className="avartar-item">
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              Email Notifications
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Configure Email Notification
            </Typography>
            <div style={{ width: "100%" }}>
              <Switch {...label} disabled />
              &nbsp;Receive notifications from email
            </div>
            <div style={{ width: "100%" }}>
              <Switch {...label} disabled />
              &nbsp;Important Email
            </div>
          </div>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                borderBottom: "#a0a0a0 solid 1px",
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={3}
                >
                  <img
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                    }}
                    src={
                      avartar
                        ? avartar
                        : `${process.env.PUBLIC_URL}/images/profile-avatar.png`
                    }
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={6}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-email">
                      Email
                    </InputLabel>
                    <Input
                      id="standard-adornment-email"
                      defaultValue={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-linkX">
                      Link X
                    </InputLabel>
                    <Input
                      id="standard-adornment-linkX-disabled"
                      disabled
                      defaultValue={profile?.link_x}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-wallet">
                      Metamask Add
                    </InputLabel>
                    <Input
                      id="standard-adornment-wallet-disabled"
                      disabled
                      defaultValue={walletAddress}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size="grow"
              ></Grid>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={9}
              ></Grid>
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={1.2}
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "#3e63dd",
                  color: "#fff",
                  fontSize: "20px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={update}
              >
                <Box>Save</Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openTelegram}
        onClose={handleCloseTelegram}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                borderBottom: "#a0a0a0 solid 1px",
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={3}
                >
                  <img
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                    }}
                    src={
                      avartar
                        ? avartar
                        : `${process.env.PUBLIC_URL}/images/profile-avatar.png`
                    }
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={6}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-telegram">
                      Telegram
                    </InputLabel>
                    <Input
                      id="standard-adornment-telegram"
                      defaultValue={telegram}
                      onChange={(event) => {
                        setTelegram(event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-linkX">
                      Link X
                    </InputLabel>
                    <Input
                      id="standard-adornment-linkX-disabled"
                      disabled
                      defaultValue={profile?.link_x}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-wallet">
                      Metamask Add
                    </InputLabel>
                    <Input
                      id="standard-adornment-wallet-disabled"
                      disabled
                      defaultValue={walletAddress}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size="grow"
              ></Grid>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={9}
              ></Grid>
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={1.2}
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "#3e63dd",
                  color: "#fff",
                  fontSize: "20px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={update}
              >
                <Box>Save</Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openDiscord}
        onClose={handleCloseDiscord}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                borderBottom: "#a0a0a0 solid 1px",
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={3}
                >
                  <img
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                    }}
                    src={
                      avartar
                        ? avartar
                        : `${process.env.PUBLIC_URL}/images/profile-avatar.png`
                    }
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  size={6}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-discord">
                      Discord
                    </InputLabel>
                    <Input
                      id="standard-adornment-discord"
                      defaultValue={discord}
                      onChange={(event) => {
                        setDiscord(event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-linkX">
                      Link X
                    </InputLabel>
                    <Input
                      id="standard-adornment-linkX-disabled"
                      disabled
                      defaultValue={profile?.link_x}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-wallet">
                      Metamask Add
                    </InputLabel>
                    <Input
                      id="standard-adornment-wallet-disabled"
                      disabled
                      defaultValue={walletAddress}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size="grow"
              ></Grid>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={9}
              ></Grid>
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                size={1.2}
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "#3e63dd",
                  color: "#fff",
                  fontSize: "20px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={update}
              >
                <Box>Save</Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      {/* <Modal
                open={openX}
                onClose={handleCloseX}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box sx={{ padding: '20px' }}>
                        <Box sx={{ borderBottom: '#a0a0a0 solid 1px', fontSize: '20px', paddingBottom: '10px' }}>
                            <Grid container spacing={2}>
                                <Grid display="flex" justifyContent="center" alignItems="center" size={3}>
                                    <img style={{ width: '120px', height: '120px', borderRadius: '50%' }} src={avartar ? avartar : `${process.env.PUBLIC_URL}/images/profile-avatar.png`} />
                                </Grid>
                                <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" size={6} >
                                    <TwitterPage></TwitterPage>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                            </Grid>
                            <Grid display="flex" justifyContent="center" alignItems="center" size={9}>
                            </Grid>
                            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" size={1.2} sx={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#3e63dd', color: '#fff', fontSize: '20px', borderRadius: '10px', cursor: 'pointer' }} onClick={handleCloseX}>
                                <Box >
                                    Close
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Modal> */}
    </Box>
  );
};
export default Profile;
