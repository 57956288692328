import { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const PopupCloseOrder = ({ item, open, onClose, onConfirm }) => {


    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="confirm-modal-title"
            aria-describedby="confirm-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor:'#232323'
                }}
            >
                <Typography id="confirm-modal-title" variant="h6" component="h2">
                    Confirm action
                </Typography>
                <Typography id="confirm-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to close limit order?
                </Typography>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" color="primary" onClick={onConfirm}>
                        Confirm
                    </Button>
                    <Button variant="outlined" color="error" onClick={onClose}>
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PopupCloseOrder;
