import { useState } from "react";
import { ethers } from "ethers";
import api from '../instance_api'
import { useSnackbar } from '../component/SnackbarProvider';

// Hook hoàn phí ETH
const useRefundEth = (infuraUrl, privateKey) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const showSnackbar = useSnackbar();

  // Hàm xử lý hoàn phí ETH
  const refundEth = async (recipientAddress, refundAmount, txId) => {
    setIsLoading(true);
    setError(null);
    setTransactionHash(null);

    try {
      // Kết nối với mạng Ethereum qua Infura
      const provider = new ethers.JsonRpcProvider(infuraUrl);

      // Tạo ví từ private key
      const wallet = new ethers.Wallet(privateKey, provider);

      // Chuyển đổi số lượng ETH thành Wei (đơn vị nhỏ nhất của ETH)
      const amountInWei = ethers.parseEther(refundAmount.toString());

      // Tạo giao dịch gửi ETH
      const tx = await wallet.sendTransaction({
        to: recipientAddress,
        value: amountInWei,
      });
      setTransactionHash(tx.hash);

      await tx.wait(); // Chờ giao dịch được xác nhận
      console.log("Transaction completed successfully:", tx);

      const response = await api.post('/api/trading_tx/post_v1_trading_transaction_refund_set', {
        tx_hash: tx.hash,
        from: tx.from,
        to: tx.to,
        amount: ethers.formatEther(tx.value),
        transfer_tx_hash: txId,
      });
      if (response && response.data && response.data.valid == 1) {
        showSnackbar(response.data.messages, 'success');
        setError(null);
      } else {
        showSnackbar(response.data.messages, 'error');
      }

    } catch (err) {
      console.error("Refund error:", err);
      setError(err.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    refundEth,
    isLoading,
    error,
    transactionHash,
  };
};

export default useRefundEth;
