import './index.css';
import '../../App.css';
import { Box, Slider, Switch, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Tab, Tabs, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsUpDown } from "@fortawesome/free-solid-svg-icons";
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';



const TryPc = () => {
    return (
        <Box component="main" sx={{ flexGrow: 1, width: '100%', backgroundColor: '#010002', minHeight: '70vh', height: 'auto' }}>
            <Box className="background-lauchapp">
                <Box className="version-mobile">
                    <Box>
                    Try the PC version first
                    </Box>
                    <Box className="laptop-icon" component="img" alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/computer-svg.png`}  />
                </Box>
            </Box>
        </Box>
    );
}
export default TryPc