import {
    Box, Toolbar, Typography,
    Tabs, Tab, Checkbox
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./announcements.style.css";
import PropTypes from 'prop-types';
import BreadcrumbsNav from "../Breadcrumbs"
import { BrowserProvider, ethers } from 'ethers';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PopupNoti from "./popupnoti"; // Import the Popup component
import api from '../../instance_api_key'
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Announcements = () => {
    const [value, setValue] = React.useState(0);
    const [dataNew, setDataNew] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [account, setAccount] = useState(null);
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    useEffect(() => {

        checkIfWalletIsConnected()

    }, []);

    const checkIfWalletIsConnected = async () => {

        const tempProvider = new BrowserProvider(window.ethereum);

        // Request account access if needed
        const accounts = await tempProvider.send('eth_requestAccounts', []);
        setAccount(accounts[0]);
        getDataNoti(accounts[0], 1);
    };

    const getDataNoti = (addres, status) => {
        api.post(`/api/notification/get_by_status`, { idAcc: addres, status: status })
            .then(response => {
                if(response.data){
                    response.data.forEach(element => {
                        element.date = formatDate(element.created);
                        if(element.content && element.content.length > 20) {
                            element.message = element.content.slice(0, 20) + '...';
                        }
                    });
                }
                console.log(response.data);
                setDataNew(response.data);
            })
            .catch(error => console.error(error));
    }
    const convertDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const res = date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          return res;
    }
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        date.setHours(date.getHours() - 7);

        // Lấy ngày, tháng, năm
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng tính từ 0
        const year = date.getFullYear();
      
        // Lấy giờ và phút, chuyển sang giờ 12h
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
      
        hours = hours % 12 || 12; // Chuyển sang giờ 12h, nếu là 0 thì chuyển thành 12
      
        return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
      };
    const [isOpenNoti, setIsOpenNoti] = useState(false);
    const [id, setId] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [date, setDate] = useState("");
    const togglePopupNoti = (event)=> () => {
        setIsOpenNoti(!isOpenNoti);
        if(isOpenNoti === false) {
            UpdateNewById(event.id)
        }
        setId(event.id);
        setTitle(event.title);
        setContent(event.content);
        setDate(event.date);
    };

    const UpdateNewById = (id) => {
        api.get(`/api/notification/update_new_by_id/${id}`)
            .then(response => {
                if(response.data && response.data[0].valid === true){
                    getDataNoti(account, 1);
                }
            })
            .catch(error => console.error(error));
    }
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Typography variant="h5" gutterBottom>
                        Announcements
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Stay updated with the latest announcements and exclusive offers available at X1K!
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 9, lg: 10 }}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="What’s New" {...a11yProps(0)} />
                                <Tab label="Offer" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Grid container spacing={3}>
                                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                                    {dataNew.map(item => (
                                        <div className={`noti ${item.new === true ? 'active' : ''}`} key={item.id} style={{ display: 'flex', padding: '5px', marginTop: '10px', borderBottom: '2px solid #ececec' }} >
                                            <div style={{ width: '10%' }}>
                                                <img className="img-av" src={`${process.env.PUBLIC_URL}/images/announcement1.png`} />
                                            </div>
                                            <div style={{ width: '80%' }}>
                                                <div className="title-noti" style={{ fontWeight: 'bold' }} onClick={togglePopupNoti(item)}>{item.title}</div>
                                                <div>{item.message}</div>
                                            </div>
                                            <div style={{ width: '15%' }}>{item.date}</div>
                                            {isOpenNoti && (
                                            <PopupNoti
                                                title={title}
                                                id={id}
                                                content={content}
                                                date={date}
                                                handleClose={togglePopupNoti(item)}
                                            />
                                        )}
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            Item Two
                        </CustomTabPanel>
                    </Box>
                </Grid>
                <Grid item size={{ xs: 12, md: 3, lg: 2 }}>
                    <div style={{ padding: '10px', border: '2px solid #ececec', borderRadius: '15px' }}>
                        <div style={{ fontWeight: 'bold' }}>Filter</div>
                        <div>
                            <Checkbox {...label} />&nbsp;All
                        </div>
                        <div><Checkbox {...label} />&nbsp;System</div>
                        <div><Checkbox {...label} />&nbsp;Advertisement</div>
                        <div><Checkbox {...label} />&nbsp;Other</div>
                        <div>Select all | Unselect all</div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};
export default Announcements
