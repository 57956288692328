import { useEffect } from "react";
import { CssBaseline, Box } from '@mui/material';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import MainDashboard from './component/main-dashboard/main-dashboard';
import MainProfile from './component/main-profile/main-profile';
import { SnackbarProvider } from './component/SnackbarProvider';


const DashboardLayout = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = navigator.userAgent;
    // Kiểm tra từ khóa "Firefox" trong userAgent

    const handleBeforeUnload = () => {
      sessionStorage.clear(); // Xóa toàn bộ sessionStorage
    };

    if (userAgent.includes("OPR")) {
        window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("sessionId", new Date().toISOString());
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        localStorage.setItem('wallet_address', accounts[0]);
        sessionStorage.removeItem('account');
        navigate('/dashboard');
        window.location.reload();
      });
    } else {
      console.error('MetaMask is not installed.');
    }
  }, []);

  return (
    <Box>
      <CssBaseline />
      <SnackbarProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard/*" element={<MainDashboard />} />
          <Route path="/profile/*" element={<MainProfile />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </SnackbarProvider>
    </Box>
  );
};

export default DashboardLayout;

