import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { BrowserProvider, ethers } from 'ethers';
import { useNavigate } from "react-router-dom";
import { baseChainId, baseChainParams } from "../constants/base_eth_chain_config"
import { sepoliaChainId, sepoliaChainParams } from "../constants/sepolia_eth_chain_config"
import { baseTestnetChainId, baseTestnetChainParams } from "../constants/base_testnet_sepolia_chain_config"

import api_key from '../instance_api_key';
import axios from 'axios';

export default function PrimarySearchAppBarProfile({ toogleSidebar }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);

  const [recipientAddress, setRecipientAddress] = useState('');
  const [amount, setAmount] = useState('0.001');
  const [txHash, setTxHash] = useState(null);
  const [totalNew, setTotalNew] = useState(0);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleNavigateProfile = () => {
    navigate("/profile");
  };

  const handleNavigateDashboard = () => {
    navigate("/dashboard");
  };

  const handleNavigateNoti = () => {
    navigate("/profile/announcements");
  };

  // Automatically connect if already connected
  useEffect(() => {

    checkIfWalletIsConnected();

  }, []);

  const checkIfWalletIsConnected = async () => {

    const tempProvider = new BrowserProvider(window.ethereum);
    setProvider(tempProvider);

    // Request account access if needed
    const accounts = await tempProvider.send('eth_requestAccounts', []);
    setAccount(accounts[0]);

    // Get the signer
    const tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    fetchChainId(tempProvider);
    getTotalNew(accounts[0]);
  };

  const getTotalNew = (addres) => {
    api_key.get(`/api/notification/get_total_new/${addres}`)
    .then(response => {
      setTotalNew(response.data?.[0].total);
    })
    .catch(error => console.error(error));
  }

  const fetchChainId = async (tempProvider) => {
    const network = tempProvider && await tempProvider.getNetwork();
    if (network) {
      setChainId(network.name);
    }
  };

  async function switchToBaseChain() {
    const { ethereum } = window;
    if (!ethereum) {
      alert('MetaMask is required!');
      return;
    }

    try {
      // Request a chain switch to Base
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: baseChainId }],
      });
    } catch (switchError) {
      // If the chain has not been added, try adding it
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [baseChainParams],
          });
        } catch (addError) {
          console.error('Error adding the chain:', addError);
        }
      } else {
        console.error('Error switching the chain:', switchError);
      }
    }
  }

  async function switchToBaseSepoliaChain() {
    const { ethereum } = window;
    if (!ethereum) {
      alert('MetaMask is required!');
      return;
    }

    try {
      // Request a chain switch to Base
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: baseTestnetChainId }],
      });
    } catch (switchError) {
      // If the chain has not been added, try adding it
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [baseTestnetChainParams],
          });
        } catch (addError) {
          console.error('Error adding the chain:', addError);
        }
      } else {
        console.error('Error switching the chain:', switchError);
      }
    }
  }

  async function switchToSepoliaChain() {
    const { ethereum } = window;
    if (!ethereum) {
      alert('MetaMask is required!');
      return;
    }

    try {
      // Request a chain switch to Sepolia
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: sepoliaChainId }],
      });
    } catch (switchError) {
      // If the chain has not been added, try adding it
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [sepoliaChainParams],
          });
        } catch (addError) {
          console.error('Error adding the chain:', addError);
        }
      } else {
        console.error('Error switching the chain:', switchError);
      }
    }
  }

  // Function to send Sepolia ETH
  const sendTransaction = async () => {
    if (!window.ethereum) {
      alert('MetaMask is required!');
      return;
    }

    try {
      // Connect to MetaMask
      const provider = new ethers.BrowserProvider(window.ethereum);
      await provider.send('eth_requestAccounts', []); // Request account access
      const signer1 = await provider.getSigner();

      // Create transaction object
      const tx = {
        to: '0x16b303859738cfade46b00ff6457b923de75ce53',
        value: ethers.parseEther(amount), // Convert ETH to wei
      };

      // Send the transaction
      const transactionResponse = await signer1.sendTransaction(tx);
      setTxHash(transactionResponse.hash); // Store the transaction hash
      console.log('Transaction sent:', transactionResponse);

      // Wait for transaction confirmation
      const receipt = await transactionResponse.wait();
      console.log('Transaction confirmed:', receipt);
    } catch (error) {
      console.error('Error sending transaction:', error);
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      // anchorOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right',
      // }}
      id={menuId}
      keepMounted
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right',
      // }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleNavigateProfile}>Profile</MenuItem>
      {/* {account && <MenuItem onClick={switchToBaseChain}> Switch to Base Chain</MenuItem>}
      {account && <MenuItem onClick={switchToSepoliaChain}> Switch to Sepolia Chain</MenuItem>}
      {account && <MenuItem onClick={switchToBaseSepoliaChain}> Switch to Base Sepolia Chain</MenuItem>} */}
    </Menu>
  );



  return (
    <Box>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#f3f3f3' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"

            aria-label="open drawer"
            sx={{ mr: 2, color: '#6e6e6e' }}
            onClick={toogleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <Box component="img" sx={{ cursor: 'pointer' }} onClick={handleNavigateDashboard}
            alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/x1k_logo.png`} onContextMenu={handleNavigateDashboard}/>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton> */}
            {/* <Button variant="contained">New x1K Challenge</Button> */}
            <IconButton
              size="large"
              aria-label="show new notifications"
              color="primary"
              onClick={handleNavigateNoti}
            >
              <Badge badgeContent={totalNew} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              sx={{ color: '#6e6e6e' }}
            >
              {!account && <AccountCircle />}
              {account && <Avatar alt="Metamask wallet" src={`${process.env.PUBLIC_URL}/images/avatar.png`} />}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}

