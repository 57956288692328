import { Toolbar, Drawer, Box } from "@mui/material";
import './index.css';
import '../../App.css';
import Grid from "@mui/material/Grid2";
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import React, { useState, useEffect, } from 'react';
import PrimaryFooter from '../X1kFooter';
import axios from 'axios';
import api from '../../instance_api';
import api_no_auth from '../../instance_api_no_authentication';
import MenuIcon from '@mui/icons-material/Menu';
const drawerWidth = 240;

const Doc = () => {
    const [selectMenu, setSelectMenu] = useState("1");
    const [dataCapitalExpansion, setDataCapitalExpansion] = useState([]);
    let isEffectExecuted = false;
    useEffect(() => {
        if (!isEffectExecuted) {
            isEffectExecuted = true;

            api_no_auth.get(`/api/docs/getDocsByType/CapitalExpansion`)
                .then(response => {
                    if (response.data) {
                        response.data?.forEach(element => {
                            element.b_c2 = formatCurrency(element.c2);
                            element.b_c3 = formatCurrency(element.c3);
                        });
                    }
                    setDataCapitalExpansion(response.data);
                })
                .catch(error => console.error(error));
        }
    }, []);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
    };

    const setMenuDoc = (value) => {
        console.log(value);
        setSelectMenu(value);
        toggleVisibility()
    }

    const [isVisible, setIsVisible] = useState(true);

    const toggleVisibility = () => {
        setIsVisible(!isVisible); // Đổi trạng thái giữa true và false
    };
    return (
        <Box component="main" sx={{ flexGrow: 1, width: '100%', minHeight: '70vh', height: 'auto' }}>
            <Box className="background-doc">
                <Box className="content-doc">
                    <Grid container spacing={10}>
                        <Grid size={{ xs: 12, md: 3.5 }}>
                            <Box className={` ${isVisible ? 'left-menu-doc' : 'left-menu-doc-show'} `} sx={{ minHeight: 352, minWidth: 250 }}>
                                <SimpleTreeView oncl className="content-menu-docs" >
                                    <TreeItem itemId="1" label="Introduction" onClick={(event) => setMenuDoc("1")}>
                                    </TreeItem>
                                    <TreeItem itemId="2" label="Comparison with Current Prop Trading Firms" onClick={(event) => setMenuDoc("2")}>
                                    </TreeItem>
                                    {/* <TreeItem itemId="3" label="Tokenomics" onClick={(event) => setMenuDoc("3")}>
                                    </TreeItem> */}
                                    <TreeItem itemId="4" label="Protocol Profits and Distribution" onClick={(event) => setMenuDoc("4")}>
                                    </TreeItem>
                                    <TreeItem itemId="5" label="Prop Firm Funding Packages" onClick={(event) => setMenuDoc("5")}>
                                    </TreeItem>
                                    <TreeItem itemId="6" label="Evaluation Process" onClick={(event) => setMenuDoc("6")}>
                                    </TreeItem>
                                    <TreeItem itemId="7" label="Capital Upgrading Process" onClick={(event) => setMenuDoc("7")}>
                                    </TreeItem>
                                    <TreeItem itemId="8" label="Reward" onClick={(event) => setMenuDoc("8")}>
                                    </TreeItem>
                                    <TreeItem itemId="9" label="Security" onClick={(event) => setMenuDoc("9")}>
                                    </TreeItem>
                                    <TreeItem itemId="10" label="Governance" onClick={(event) => setMenuDoc("10")}>
                                    </TreeItem>
                                    <TreeItem itemId="11" label="Staking" onClick={(event) => setMenuDoc("11")}>
                                    </TreeItem>
                                    <TreeItem itemId="12" label="Reasons why the X1K token may increase in value" onClick={(event) => setMenuDoc("12")}>
                                    </TreeItem>
                                    <TreeItem itemId="13" label="FAQ" onClick={(event) => setMenuDoc("13")}>
                                    </TreeItem>
                                </SimpleTreeView>

                                <span className="close-icon-docs" onClick={toggleVisibility}>
                                    &times;
                                </span>
                            </Box>
                            {isVisible &&
                                <Box className="menu-bars-docs" onClick={toggleVisibility}>
                                    <MenuIcon sx={{ width: '100%' }} />
                                </Box>}
                        </Grid>
                        <Grid size={{ xs: 12, md: 8.5 }} className='right-docs'>
                            {selectMenu === "1" &&
                                <Box>
                                    <h2>Introduction</h2>
                                    <Box><span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> is a prop trading firm providing funding for crypto traders to engage in futures trading. We’re looking for skilled traders who believe they have the talent and ability to generate stable, long-term profits in the crypto market but lack the substantial capital to start trading.</Box>
                                    <br></br>
                                    <Box>At <span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> , we are committed to offering up to 1,000 times the initial registration fee as capital, with a maximum of $500,000 if you pass our two-stage challenge. Additionally, X1Kfunded.finance will refund 100% of the registration fee if you make at least $100 in profit on your first withdrawal. Traders who pass Stage 1 of the evaluation will receive a small reward.  </Box>
                                    <br></br>
                                    <Box><span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> also offers various earning opportunities, such as referral commissions, trading volume bonuses, and staking.</Box>
                                    {/* <br></br>
                                    <Box><span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> sẽ có một phần thưởng nhỏ khi trader vượt qua được vòng khảo sát 1.</Box>
                                    <br></br>
                                    <Box>Ngoài ra ở <span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> trader sẽ có nhiều cơ hội kiếm tiền khác như ref commission, ref volume trading ,staking.</Box> */}
                                    <br></br>
                                    <h3>Solutions We Offer Traders:</h3>
                                    <Box>Experience demo trading with real market data provided transparently by top exchanges like Binance, OKX, MEXC, and Gate.</Box>
                                    <br></br>
                                    <Box>Build stable trading skills and create a sustainable, long-term income.</Box>
                                    <br></br>
                                    <Box>Access up to 1,000x your initial capital, with a maximum allocation of $500K and profit-sharing of up to 90% of your total generated profits.</Box>
                                </Box>
                            }
                            {selectMenu === "2" &&
                                <Box>
                                    <h2>Comparison with Current Prop Trading Firms</h2>
                                    <Box><span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> is among the first prop trading firms to integrate blockchain technology into its funding program for crypto traders.</Box>
                                    <br></br>
                                    <Box>Although using blockchain can present some challenges compared to Web2-based prop firms, such as slightly longer order execution times or data synchronization difficulties, it also brings significant benefits to traders. These include transparent pricing provided by third parties and multiple earning methods beyond just sharing in profits made directly through trading.</Box>
                                </Box>
                            }
                            {selectMenu === "3" &&
                                <Box>
                                    <h2>Tokenomics</h2>
                                    <Box><span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> has a maximum supply of 100M X1K tokens. Minting beyond this max supply will only occur if approved by GMX governance.</Box>
                                    {/* <br></br>
                                    <Box>Tất nhiên cũng sẽ có những nhược điểm trên blockchain so quỹ cấp vốn web2 hiện tại như thời gian thực thi lệnh sẽ lâu hơn,đồng bộ dữ liệu khó hơn .Nhưng nếu đem những mặt tích cực ra thì sẽ nhiều và sẽ tạo ra nhiều quyền lợi cho user hơn như tính minh bạch đường giá được cung cấp bởi bên thứ 3,nhiều cách kiếm lợi nhuận khác ngoài việc ăn chia chỉ riêng số lợi nhuận mà trader đó tạo ra.</Box> */}
                                    <br></br>
                                    <Box>15% IDO</Box>
                                    <Box>2% add LP dex</Box>
                                    <Box>35% reward pool  </Box>
                                    <Box>10% for airdrops (divided into three rounds)</Box>
                                    <Box>8% referral commission (unlocking at market cap targets of $10M, $20M, and $50M)</Box>
                                    <Box>15% for backers (divided into three pools: pre-DEX listing, 3 months after listing, and 6 months after listing, with a 3-month lock and subsequent 10% unlock in the 4th month, followed by 90% vesting over 24 months)  </Box>
                                    <Box>15% for the team (same locking and vesting schedule as backers)</Box>
                                    <Box>Tax 2/2 for (CEX listing pool and DEX liquidity).</Box>
                                </Box>
                            }
                            {selectMenu === "4" &&
                                <Box>
                                    <h2>Protocol Profits and Distribution</h2>
                                    <h3>Revenue from Trading Volume Fees:</h3>
                                    <Box>Distribution: </Box>
                                    <Box>+ 40% to staking pool and rewards</Box>
                                    <Box>+ 60% to research and development pool </Box>
                                    <h3>Revenue from Registration Fees for the Prop Trading Challenge:</h3>
                                    <Box>Distribution : </Box>
                                    <Box>+ Reserved pool </Box>
                                    <Box>+ Pro copy trader pool  </Box>
                                    <Box>+ Crypto investment fund of the project </Box>
                                </Box>
                            }
                            {selectMenu === "5" &&
                                <Box>
                                    <h2>Prop Firm Funding Packages</h2>
                                    <h3><span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> offers two funding challenge modes:</h3>
                                    <Box>- Dollar-based packages (with fees calculated in X1K tokens pegged to dollar value in real-time)</Box>
                                    <Box>- Token-based packages (coming soon)</Box>
                                    <ul>
                                        <li>
                                            <Box>For dollar-based funding packages:</Box>
                                            <Box sx={{ width: '100%' }} className="prop-web">
                                                <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                                    <Grid size={2} sx={{ fontWeight: 'bold' }}>Account size</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>5.000$</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>10.000$</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>20.000$</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>50.000$</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>100.000$</Grid>
                                                </Grid>
                                                <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                                    <Grid size={2} sx={{ fontWeight: 'bold' }}>Cost</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>39$</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>76$</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>186$</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>268$</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>499$</Grid>
                                                </Grid>
                                            </Box>
                                            <Box className="prop-mobile">
                                                <Box sx={{ width: '20%' }}>
                                                    <Grid container spacing={0} sx={{ borderBottom: '1px solid #ccc ', display: 'flex'}}>
                                                        <Grid sx={{ fontWeight: 'bold'}}>Account</Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc '}}>
                                                        <Grid size={2} sx={{ fontWeight: 'bold' }}>Cost</Grid>
                                                    </Grid>
                                                </Box>
                                                <Box sx={{ width: '70%', overflow:'auto' }}>
                                                    <Grid container spacing={0} sx={{ borderBottom: '1px solid #ccc ', display: 'flex' }} columns={15}>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>5K$</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>10K$</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>20K$</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>50K$</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>100K$</Grid>
                                                    </Grid>
                                                    <Grid container spacing={0} sx={{ borderBottom: '1px solid #ccc ' }} columns={15}>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>39$</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>76$</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>186$</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>268$</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>499$</Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </li>
                                        <br></br>

                                        <li>
                                            <Box>For token-based funding packages (coming soon):  </Box>
                                            <Box sx={{ width: '100%' }} className="prop-web">
                                                <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                                    <Grid size={2} sx={{ fontWeight: 'bold' }}>Account size</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>5.000 X1K</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>10.000 X1K</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>20.000 X1K</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>50.000 X1K</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>100.000 X1K</Grid>
                                                </Grid>
                                                <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                                    <Grid size={2} sx={{ fontWeight: 'bold' }}>Cost</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>39 X1K</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>76 X1K</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>186 X1K</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>268 X1K</Grid>
                                                    <Grid size={2} sx={{ textAlign: 'center' }}>499 X1K</Grid>
                                                </Grid>
                                            </Box>
                                            <Box className="prop-mobile">
                                                <Box sx={{ width: '20%' }}>
                                                    <Grid container spacing={0} sx={{ borderBottom: '1px solid #ccc ', display: 'flex'}}>
                                                        <Grid sx={{ fontWeight: 'bold'}}>Account</Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc '}}>
                                                        <Grid size={2} sx={{ fontWeight: 'bold' }}>Cost</Grid>
                                                    </Grid>
                                                </Box>
                                                <Box sx={{ width: '70%', overflow:'auto' }}>
                                                    <Grid container spacing={0} sx={{ borderBottom: '1px solid #ccc ', display: 'flex' }} columns={15}>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>5K X1K</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>10K X1K</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>20K X1K</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>50K X1K</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>100K X1K</Grid>
                                                    </Grid>
                                                    <Grid container spacing={0} sx={{ borderBottom: '1px solid #ccc ' }} columns={15}>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>39 X1K</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>76 X1K</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>186 X1K</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>268 X1K</Grid>
                                                        <Grid size={3} sx={{ textAlign: 'center' }}>499 X1K</Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </li>
                                    </ul>
                                </Box>
                            }

                            {selectMenu === "6" &&
                                <Box>
                                    <h2>Evaluation Process</h2>
                                    <h3>The two-step evaluation process includes: </h3>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid size={1}>Stage 1:</Grid>
                                            <Grid size={11}>
                                                <ul class="" style={{ margin: 0 }}>
                                                    <li>Profit target of 8%</li>
                                                    <li>Minimum of 3 profitable days at 0.5% of funded account size</li>
                                                    <li>Maximum daily drawdown: 5%</li>
                                                    <li>Maximum overall drawdown: 10%.</li>
                                                    {/* <li>
                                                        <Box> Giới hạn Lợi nhuận tối đa cho một giao dịch là 40%/ tổng số lợi nhuận đã tạo ra.</Box>
                                                        <Box> (VD :tổng số lợi nhuận bạn thu được là 200$,vì vậy trong đó một lệnh giao dịch tối đa chỉ được chiếm 40% tức 80$/tổng 200$ lợi nhuận này).</Box>

                                                    </li>
                                                    <li>Không giới hạn số ngày giao dịch</li> */}
                                                </ul>
                                            </Grid>
                                        </Grid>
                                        <br></br>
                                        <Grid container spacing={2}>
                                            <Grid size={1}>Stage 2:</Grid>
                                            <Grid size={11}>
                                                <ul class="" style={{ margin: 0 }}>
                                                    <li>Profit target of 5%</li>
                                                    <li>Minimum of 3 profitable days at 0.5%</li>
                                                    <li>Maximum daily and overall drawdown rules are the same as Stage 1</li>
                                                    {/* <li>Giới hạn sụt giảm tổng tk cấp vốn là 10%</li>
                                                    <li>Giới hạn Lợi nhuận tối đa cho một giao dịch là 40%/ tổng số lợi nhuận đã tạo ra.</li>
                                                    <li>Không giới hạn số ngày giao dịch</li> */}
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <h3>Example :</h3>
                                    <Box>For a $100,000 funding package, the daily drawdown limit would be $5,000, and the profit target would be $500.</Box>
                                    {/* <Box>Vì vậy mục tiêu tối thiểu 0.5% ở đây sẽ = 100K  x  0.5%   =   500$</Box>
                                    <Box>Giới hạn sụt giảm lấy theo mức Balance đầu ngày (đầu ngày được tính sau khi reset múi giờ GMT +8)</Box>
                                    <Box>Giới hạn 5% ở đây được xét mốc cố định từ đầu theo gói cấp vốn</Box>
                                    <Box>5% của gói cấp vốn 100K là   5K$</Box>
                                    <Box>5% của gói cấp vốn 20K là   1K$	</Box> */}
                                    {/* <br></br>
                                    <Box>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>(*)Lưu ý : </span>
                                        <span>Khi Equity đầu ngày = Balance đầu ngày có nghĩa bạn không giữ giao dịch nào qua đêm</span>
                                    </Box>
                                    */}
                                    <Box>
                                        <ul class="" >
                                            <li>
                                                <h4>Case 1: Equity = balance = $100.000</h4>
                                                <Box>You start the first trading day with an account where Equity = Balance = $100.000	</Box>
                                                <Box>100.000-5.000= 95.000$.</Box>
                                                <Box><span className="conclude">Conclusion:</span> You are not allowed to let the account Equity fall below $95.000</Box>
                                            </li>
                                            <li>
                                                <h4>Case 2: Equity = balance = $105.000</h4>
                                                <Box>When you have a profit compared to the initially provided capital.</Box>
                                                <Box>You start the trading day with an account where Equity = Balance = $105.000</Box>
                                                <Box>105.000 - 5.000 = $100.000</Box>
                                                <Box><span className="conclude">Conclusion:</span> You are not allowed to let the account Equity fall below $100.000</Box>
                                            </li>
                                            <li>
                                                <h4>Case 3: Equity = balance = $98.000</h4>
                                                <Box>When you have a loss compared to the initial capital.</Box>
                                                <Box>You start the trading day with an account where Equity = Balance = $98.000</Box>
                                                <Box>98.000 - 5.000 = $93.000</Box>
                                                <Box><span className="conclude">Conclusion:</span> You are not allowed to let the account Equity fall below $93.000</Box>
                                            </li>
                                            <li>
                                                <h4>Case 4: Equity  = $106.000 ,Balance = $100.000	</h4>
                                                <Box>You hold positions overnight and start a trading day with open positions having a profit of $6.000</Box>
                                                <Box>X1K is always determined based on the beginning-of-day Balance, so the daily drawdown limit is:</Box>
                                                <Box>100.000- 5.000  = $95.000</Box>
                                                <Box><span className="conclude">Conclusion:</span> You are not allowed to incur an additional loss of $11,000, bringing the account Equity below $95,000</Box>
                                            </li>
                                            <li>
                                                <h4>Case 5: Equity = 98.000 ,Balance = 100.000</h4>
                                                <Box>You hold positions overnight and start a trading day with open positions having a loss of $2,000.</Box>
                                                <Box>X1K is always determined based on the beginning-of-day Balance, so the daily drawdown limit is:</Box>
                                                <Box>100.000 - 5.000 = $95.000</Box>
                                                <Box><span className="conclude">Conclusion:</span> You are not allowed to incur an additional loss of $3,000, which would bring the account Equity below $95,000.</Box>
                                            </li>
                                        </ul>
                                    </Box>
                                    <br></br>
                                    <Box>
                                        <Box style={{ color: 'red', fontWeight: 'bold' }}>(*)Inactivity : </Box>
                                        <ul class="" >
                                            <li>Accounts inactive for 30 days will be closed.</li>
                                            {/* <li>Chu kì 14 ngày sẽ được đếm lại khi bạn mở rộng vốn.</li> */}
                                            <li>Upon a minimum profit of $100, LIVE accounts may request payout and receive the refunded registration fee.</li>
                                            {/* <li>Ngoài ra user không được thực hiện các hành vi gây tổn thất cho dự án.</li>
                                            <li>1 User nên chỉ dùng 1 ví login. Nếu có đăng kí nhiều ví thì nên thông báo support để được hỗ trợ, cập nhật.</li>
                                            <li>Không được giao dịch Hedging trong một tài khoản, giữa các tài khoản hoặc giữa các user khác nhau.</li>
                                            <li>Không được copy trade của người khác.Copy trade các tài khoản cấp vốn trên cùng 1 ví metamask được chấp nhận.</li>
                                            <li>Mọi nguyên tắc giao dịch của user nếu ko đúng theo quy định và hướng đi của Quỹ cấp vốn sẽ được thông báo đến email và chuông báo.</li>
                                            <li>Các hành vi cố chấp gây tổn thất dù đã được thông báo sẽ bị khoá tài khoản.Trong thời gian khoá tài khoản user có 7 ngày khiếu nại.</li>
                                            <li>Giao dịch tin tức được phép thực hiện nhưng user không được quỹ khuyến khích giao dịch trong thời điểm nhạy cảm này.Vì có độ giãn giá lớn.</li>
                                            <li style={{ color: 'red' }}>Tài khoản LIVE Có yêu cầu như loại tài khoản khảo sát. Nhưng phí giao dịch sẽ được ưu đãi thấp hơn nhiều so với tài khoản khảo sát. </li> */}
                                        </ul>
                                    </Box>

                                </Box>
                            }

                            {selectMenu === "7" &&
                                <Box>
                                    <h2>Capital Upgrading Process</h2>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                            <Grid size={3}></Grid>
                                            <Grid size={3} sx={{ textAlign: 'center', fontWeight: 'bold' }}>Account Balance</Grid>
                                            <Grid size={3} sx={{ textAlign: 'center', fontWeight: 'bold' }}>Balance Target</Grid>
                                            <Grid size={3} sx={{ textAlign: 'center', fontWeight: 'bold' }}>Payout ratio</Grid>
                                        </Grid>
                                        {dataCapitalExpansion.map(item => (
                                            <Grid key={item.id} container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                                <Grid size={3} sx={{ fontWeight: 'bold' }}>{item.c1}</Grid>
                                                <Grid size={3} sx={{ textAlign: 'center' }}>{item.b_c2}</Grid>
                                                <Grid size={3} sx={{ textAlign: 'center' }}>{item.b_c3}</Grid>
                                                <Grid size={3} sx={{ textAlign: 'center' }}>{item.c4}</Grid>
                                            </Grid>
                                        ))}
                                    </Box>
                                    <br></br>
                                    <Box>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>(*)Payout Eligibility Requirements: </span>
                                        <span>Account upgrade balance and payout ratios vary by account size, with progression thresholds increasing per account.</span>
                                    </Box>
                                    <br></br>
                                    <Box>Maximum account size is $500K.</Box>
                                    <br></br>
                                    <Box>With a 10% cumulative profit target and minimum 0.5% profit on 3 positive trading days required for each level upgrade.The profit generated from a single trade must not exceed 40% of the total profit.</Box>
                                </Box>
                            }
                            {selectMenu === "8" &&
                                <Box>
                                    <h2>Reward</h2>
                                    <Box><span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> offers multiple ways to earn, including up to 90% profit share on your LIVE account.:</Box>
                                    <ul class="" style={{ margin: 0 }}>
                                        <li>Referral commissions.</li>
                                        <li>Participation in airdrop pools.</li>
                                        <li>The project has a referral pool where you can earn a 20% commission on funding packages once the project’s market cap reaches targets of $10M, $20M, and $50M.</li>
                                        <li>Profit-sharing based on the future trading fees generated by your referrals.</li>
                                    </ul>
                                </Box>
                            }
                            {selectMenu === "9" &&
                                <Box>
                                    <h2>Security</h2>
                                    <Box>Audit :...</Box>
                                    <br></br>
                                    <Box>Bug Bounty: There is an active bounty for the repositories at x1Kfunded@gmail.com or on the project’s Discord.</Box>
                                </Box>
                            }
                            {selectMenu === "10" &&
                                <Box>
                                    <h2>Governance</h2>
                                    <Box>The X1K token governs the X1K DAO. Its introduction marks a significant advancement in the governance mechanism compared to previous iterations. It completes the implementation of a fully on-chain voting system that is transparent and includes delegation services.</Box>
                                    <br></br>
                                    <Box>
                                        <Box style={{ color: 'red', fontWeight: 'bold' }}>(*)Note: </Box>
                                        <ul class="" style={{ margin: 0 }}>
                                            <li>Unstaked X1K tokens cannot be used to vote on governance proposals for the project. Tokens must be staked first to receive X1KDAO tokens, which grant governance rights.</li>
                                            <li>When users unstake their X1K tokens, they will lose their voting rights within the X1K governance framework.</li>
                                        </ul>
                                    </Box>
                                </Box>
                            }
                            {selectMenu === "11" &&
                                <Box>
                                    <h2>Staking</h2>
                                    <Box style={{ color: 'red' }}>Staking periods can last up to 24 months, with longer staking periods earning more X1K DAO points</Box>
                                    <br></br>
                                    <Box>These points are distributed across staking pools for voting and weekly rewards.</Box>
                                    <br></br>
                                    <Grid container spacing={2}>
                                        <Grid size={1}>Example:</Grid>
                                        <Grid size={11}>
                                            <ul class="" style={{ margin: 0 }}>
                                                <li>
                                                    <Box>If a user stakes 1,000 X1K tokens for 10 months, their staking points are calculated as:</Box>
                                                    <Box>1000/24×10 = 416.17 X1K DAO points.</Box>
                                                </li>
                                                <li>
                                                    <Box>If a user stakes 1,000 X1K tokens for 15 months, their staking points are calculated as: </Box>
                                                    <Box>1000/24×15  = 625 X1K DAO points.</Box>
                                                </li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Box>These points contribute to the overall staking pool, determining governance voting power and share in the reward pool, which distributes every 7 days.</Box>
                                    <br></br>
                                    <Box style={{ color: 'red' }}>Additional Benefits:</Box>
                                    <ul class="" style={{ margin: 0 }}>
                                        <li>Users with over 2000 staking points (valued at approximately $100 stake 24 months when market cap is $5M) receive a 10% discount on transaction fees until the end of their staking cycle.</li>
                                        <li>Higher trading volume leads to greater fee reductions, with a maximum discount of 10% at three volume thresholds: 5M, 10M, and 20M.</li>
                                        <li>Users must have a minimum trading volume of 100K to earn referral rewards or participate in the project's referral programs.</li>
                                    </ul>
                                </Box>
                            }
                            {selectMenu === "12" &&
                                <Box>
                                    <h2>Reasons why the X1K token may increase in value </h2>
                                    <h3>Comparison of Economic Models with GameFi Projects from 2021</h3>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                            <Grid size={3}></Grid>
                                            <Grid size={4} sx={{ textAlign: 'center', fontWeight: 'bold' }}>Gamefi</Grid>
                                            <Grid size={4} sx={{ textAlign: 'center', fontWeight: 'bold' }}>X1K</Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                            <Grid size={3} sx={{ fontWeight: 'bold' }}>Number of Tokens in the Project </Grid>
                                            <Grid size={4} sx={{ textAlign: 'center' }}>3 (1 governance token, 1 reward token, 1 type of NFT)</Grid>
                                            <Grid size={4} sx={{ textAlign: 'center' }}>1 governance and reward token</Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                            <Grid size={3} sx={{ fontWeight: 'bold' }}>Who Profits</Grid>
                                            <Grid size={4} sx={{ textAlign: 'center' }}>Anyone who participates will earn profits</Grid>
                                            <Grid size={4} sx={{ textAlign: 'center' }}>Only skilled traders</Grid>
                                        </Grid>
                                    </Box>
                                    <br></br>
                                    <Box>From the comparison above, it's easy to see that the indiscriminate inflation of reward tokens in GameFi projects and the fact that anyone can earn profits lead to the long-term devaluation of these reward tokens. Early participants have an overwhelming advantage, allowing them to join and make profits first. This results in inflation and a cycle where users buy NFTs, farm reward tokens, experience inflation of those tokens, leading to fewer users in GameFi, decreasing NFT prices, and ultimately losses for users.</Box>
                                    <br></br>
                                    <Box>In contrast, the <span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> model, where not everyone can profit by merely participating but must prove their skills in trading to access funding, represents a significant departure from the GameFi economic model of 2021. Additionally, the economic model of X1Kfunded allows for revenue generation through copy trading skilled traders and selling their trading orders to institutions, creating additional revenue streams for the project. This makes the economic model at <span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span> more efficient and optimized.</Box>

                                    <h3>Comparison with the Economic Model of DEX Futures.</h3>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                            <Grid size={2}></Grid>
                                            <Grid size={4} sx={{ textAlign: 'center', fontWeight: 'bold' }}>DEX future</Grid>
                                            <Grid size={5} sx={{ textAlign: 'center', fontWeight: 'bold' }}>X1K</Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                            <Grid size={2} sx={{ fontWeight: 'bold' }}>Token Utility</Grid>
                                            <Grid size={4} sx={{ textAlign: 'center' }}>Token used for governance and rewards for stakers</Grid>
                                            <Grid size={5} sx={{ textAlign: 'center' }}>Token used for purchasing funding packages, governance, airdrops,trading fee discounts, and staking</Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                            <Grid size={2} sx={{ fontWeight: 'bold' }}>% TGE</Grid>
                                            <Grid size={4} sx={{ textAlign: 'center' }}>15%</Grid>
                                            <Grid size={5} sx={{ textAlign: 'center' }}>70%</Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc ', padding: '10px' }}>
                                            <Grid size={2} sx={{ fontWeight: 'bold' }}>Ways to Earn Tokens</Grid>
                                            <Grid size={4} sx={{ textAlign: 'center' }}>Stake to receive rewards </Grid>
                                            <Grid size={5} sx={{ textAlign: 'center' }}>Share profits based on user performance (up to 90%)</Grid>
                                        </Grid>
                                    </Box>
                                    <br></br>
                                    <Box>The addition of token utility for purchasing funding packages enhances the economic cycle of <span style={{ fontWeight: 'bold' }}>X1Kfunded.finance</span>, thanks to increased trading volume. Moreover, the higher % TGE of the token, due to not needing to pay for a staking pool to create liquidity, boosts the intrinsic value of the X1K token by significantly reducing inflationary pressures. User skills in marketing and trading will also be further leveraged in this tokenomic model.</Box>
                                    <br></br>
                                    <Box><span className="conclude">Conclusion:</span> The X1K token offers more utility, more ways to generate profit, a higher % TGE, and interacts economically with external factors. The fact that not every user can profit creates a more stable and efficient economic model compared to older economic frameworks.</Box>
                                </Box>
                            }
                            {selectMenu === "13" &&
                                <Box>
                                    <h2>FAQ</h2>
                                    <h4>1, What benefits do token stakers get? How long do they need to stake at maximum?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Stakers will receive 40% of transaction fees. The longer the user stakes, the higher their reward percentage will be. The minimum staking period is one month, and the maximum is 24 months. There is no minimum token amount required for staking.
                                    </Box>
                                    <br></br>
                                    <h4>2, If all tokens are issued, where will the tokens to pay rewards come from?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        We will generate revenue from effective trading methods to buy tokens for rewarding traders. For example, if a trader is funded with $5,000 and earns $1,000, we will use $800 of that, based on our profit-sharing ratio, to buy tokens for users.
                                    </Box>
                                    <br></br>
                                    <h4>3, Why do you think this project won’t be a Ponzi scheme?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Let me illustrate with an example. In the GameFi trend, it’s expected that anyone can earn extra tokens, which means anyone can profit without facing any risks. However, in the X1K project, traders must pass an entry test and pay a fee for that test, so not everyone can earn additional tokens. Your ability to earn tokens largely depends on your trading skills.
                                    </Box>
                                    <br></br>
                                    <h4>4, Where will the project’s revenue come from?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Our revenue will come from copying professional traders to generate profits and from trading fees on various pairs.
                                    </Box>
                                    <br></br>
                                    <h4>5, Will the token experience inflation?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        In any sustainable economy, there will be a suitable inflation rate for development. We aim to maintain a low level of inflation to support the project's growth.
                                    </Box>
                                    <br></br>
                                    <h4>6, How will any issues I have be addressed?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        There will be a link directing users to chat directly or submit feedback in a dedicated group on Telegram and Discord. Our support team will respond as quickly as possible.
                                    </Box>
                                    <br></br>
                                    <h4>7, Why will the reward token amount not be fixed?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Because the weekly profits generated by professional users for the fund can vary.
                                    </Box>
                                    <br></br>
                                    <h4>8, Will traders get additional benefits at higher levels?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Absolutely. You will receive higher funding and a greater profit share because you've helped the fund earn more profit.
                                    </Box>
                                    <br></br>
                                    <h4>9, Why is the referral commission so high?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        When we reach market capitalization milestones of $10 million, $20 million, and $50 million, 8% of the tokenomics will be allocated to the referral pool.
                                    </Box>
                                    <br></br>
                                    <h4>10,  What drives the potential for token price growth?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        For any company to develop, its internal potential and market growth must be strong, as well as its revenue potential. We do not encourage speculative token behavior; if tokens are held by holders who understand the project’s operations and utilities, the token price will stabilize. Regarding potential: we are the first project fund to offer x1000 funding to traders, and trading will always attract users regardless of market conditions, ensuring consistent revenue and profit for the fund.
                                    </Box>
                                    <br></br>
                                    <h4>11, Am I allowed to trade on news?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Yes. However, if there’s high volatility during news announcements, you must take responsibility for your trades.
                                    </Box>
                                    <br></br>
                                    <h4>12, What happens if bugs or cheating are detected?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        If users find any system errors during their operations and report them to the team promptly, they will receive a suitable reward. However, if users exhibit any signs of cheating or exploit system errors for profit, we will immediately terminate the trading contract on their live account.
                                    </Box>
                                    <br></br>
                                    <h4>13, What constitutes a trading day?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        A trading day is defined as any day you open a new trade
                                    </Box>
                                    <Box>For example, if you open a trade on Monday and close it on Friday, that counts as one trade.</Box>
                                    <br></br>
                                    <h4>14, Is it mandatory to have a stop-loss order?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        We value disciplined traders with clear trading objectives. Therefore, having a stop-loss order is one way to demonstrate this.
                                    </Box>
                                    <br></br>
                                    <h4>15, Am I allowed to use EAs (Expert Advisors) for trading?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        We accept traders’ EAs, but third-party EAs will be prohibited as they do not accurately reflect the trader's skill level.
                                    </Box>
                                    <br></br>
                                    <h4>16, Is copying trades allowed?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Users can copy trades from funded accounts within the same on-chain wallet address.
                                    </Box>
                                    <br></br>
                                    <h4>17, Is it necessary to have a consistent trading method?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        We value traders who maintain a consistent trading approach.
                                    </Box>
                                    <br></br>
                                    <h4>18, If my account has a loss limit of $500, and a trade exceeds $501 but then immediately rebounds to $100, will I be in violation of policy?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Yes. We do not encourage users to invest with high risk.
                                    </Box>
                                    <br></br>
                                    <h4>19, Can a user create multiple accounts?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Yes, a single user can create up to three accounts using the same on-chain wallet address. Users should use only one on-chain wallet account to register for funding applications to avoid unnecessary complications.
                                    </Box>
                                    <br></br>
                                    <h4>20, Can one wallet have multiple trading accounts?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        No, you cannot create multiple trading accounts. However, one user can register for up to three packages with a maximum funding limit of $200,000 until they increase their funding level, and up to $500,000 once they have upgraded.
                                    </Box>
                                    <br></br>
                                    <h4>21, If rewards are paid in X1K tokens, how will the conversion rate be calculated?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        The token price for rewards and registration fees for funding applications will be based on real-time prices.
                                    </Box>
                                    <br></br>
                                    <h4>21, Can I open a trade in the opposite direction of an executed trade?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        You may open a trade opposite to an executed trade, but the previous executed trades will be closed at market price when you perform this action on the same trading pair. However, you can execute this action on different trading pairs without closing old trades (e.g., you can go long on the BTC/USDX pair and short on the ETH/USDX pair).
                                    </Box>
                                    <br></br>
                                    <h4>22, Is the trading volume fee during the survey different from the trading volume fee in a live account?</h4>
                                    <Box>
                                        <span style={{ color: '#28a745' }}>Response: </span>
                                        Yes. The trading volume fee in a live account will be significantly lower than when you are trading in a survey account.
                                    </Box>
                                </Box>
                            }
                        </Grid>

                    </Grid>
                </Box>
            </Box>
            <PrimaryFooter />

        </Box>

    );
};

export default Doc;
