import { useCallback, useState, useEffect } from "react";
import { debounce, uniq } from "lodash";
import axios from "axios";
export const usePriceCrypto = ({ tradeOrder, tradeTx, listTradingPair }) => {
    const [priceCrypto, setPriceCrypto] = useState(); // null | Object
    const [price, setPrice] = useState(0);
    const [selectedToken, setSelectedToken] = useState();
    const [fundingRateCrypto, setFundingRateCrypto] = useState({});
    const [loadingCrypto, setLoadingCrypto] = useState(false);
    let socket = null

    useEffect(() => {
        if (socket) {
            socket.close();
        }
        initializeWebSocket();
        // close connection
        return () => {
            if (socket) {
                socket.close();
            }
        };
        // nếu thay đổi token hoặc thêm mới tx thì khởi tạo lại ws để lắng nghe giá 
    }, [selectedToken, tradeTx, tradeOrder]);

    useEffect(() => {
        getFundingRateCrypto();
        const interval = setInterval(getFundingRateCrypto, 360000);
        return () => clearInterval(interval);
    }, [listTradingPair])



    const initializeWebSocket = useCallback(
        () => {
            const arrayTrade = [...tradeOrder, ...tradeTx].filter(c => c.trading_pair_type === "CRYPTO").map(c => c.symbol_exchange);
            if (selectedToken) {
                arrayTrade.push(selectedToken.symbol_exchange);
                setLoadingCrypto(true); 
            }
            arrayTrade.push('ETHUSDT');
            const streams = uniq(arrayTrade).map(pair => `${pair.toLowerCase()}@trade`).join('/');
            console.log("stream", streams)
            const wsUrl = `wss://stream.binance.com:9443/stream?streams=${streams}`;
            socket = new WebSocket(wsUrl);

            socket.onmessage = async (event) => {
                try {
                    if (event.data !== "Connected") {
                        const data = JSON.parse(event.data);
                        onSetPriceToken(data.data)
                    }
                } catch (error) {
                    console.log("on message error", error)
                }
            };

            socket.onclose = () => {
                console.log('WebSocket CRYPTO disconnected');
            };

            socket.onerror = (error) => {
                console.error('WebSocket CRYPTO error:', error);
            };
        },
        [selectedToken, tradeTx, tradeOrder],
    );

    const onSetPriceToken = (data) => {
        const { s, p } = data;
        const value = s.replace("USDT", "");
        if (value === "ETH") {
            setPrice(parseFloat(p).toFixed(2))
        }
        if (loadingCrypto && selectedToken?.value === value) {
            setLoadingCrypto(false);
        }
        setPriceCrypto((prev) => ({
            ...prev,
            [value]: value === 'SHIB' ? parseFloat(p) : parseFloat(p).toFixed(2) 
        }));
    }

    const onSelectCryptoToken = (token) => {
        if (selectedToken?.value === token?.value) {
            return;
        }
        setSelectedToken(token)

    }

    const getFundingRateCrypto = useCallback(async () => {
       try{
       
        const listToken = listTradingPair.filter(c => c.type === "CRYPTO" && c.status == 1);
        const promises = listToken.map(token => {
            return axios.get(`https://fapi.binance.com/fapi/v1/fundingRate?symbol=${token.value}USDC`)
        })
        const results = await Promise.allSettled(promises);
        const funding = {}
        for (const response of results) {
            if (response.value.data?.length > 0) {
                const firstFundingRate = (parseFloat(response.value.data[0].fundingRate) * 100).toFixed(5);
                funding[response.value.data[0].symbol.replace("USDC", "")] = firstFundingRate;
            }
        }
        setFundingRateCrypto(funding)
       }catch(e) {
        console.log(e);
       }
    }, [listTradingPair])

    return {
        priceCrypto,
        price,
        onSelectCryptoToken,
        fundingRateCrypto,
        loadingCrypto,
        setLoadingCrypto
    }
}