import { useCallback, useState, useEffect } from "react";
import { debounce, uniq } from "lodash";
export const usePriceFX = ({tradeOrder, tradeTx}) => {
    
    const [priceFX, setPriceFX] = useState(); // null | Object
    const [selectedFx, setSelectedFx] = useState();
    const [loadingFX, setLoadingFX] = useState(false);
    let socket = null

    // useEffect(() => {
    //     //TODO: nếu có tx đang mở hoặc order thì set lại pairFxListents để lắng nghe giá wss
    // }, [tradeTx, tradeOrder])
    

    useEffect(() => {
        if (socket) {
            socket.close();
        }
        initializeWebSocket();
        // close connection
        return () => {
            if (socket) {
              socket.close();
            }
          };
    }, [tradeOrder, tradeTx, selectedFx]);



    const initializeWebSocket = useCallback(
        () => {
            const arrayTrade = [...tradeOrder, ...tradeTx].filter(c => c.trading_pair_type === "FX").map(c => c.symbol_exchange);
            if (selectedFx) {
                arrayTrade.push(selectedFx.symbol_exchange);
                setLoadingFX(true);
            }
            if (arrayTrade.length < 1) {
                return;
            }
            console.log(arrayTrade);
            const apiKey = 'wsUfeEm4M5EhPokH6_cQ';
            socket = new WebSocket('wss://marketdata.tradermade.com/feedadv');
            socket.onopen = () => {
              console.log('WebSocket FX connected');
              socket.send(
                JSON.stringify({
                    userKey: apiKey,
                    symbol: uniq(arrayTrade).map(item => `${item}`).join(","),
                })
              );
            };
      
            socket.onmessage = async  (event) => {
                try {
                    if (event.data !== "Connected") {
                        const data = JSON.parse(event.data);
                        if (data && data.symbol && data.bid) {
                            onSetPriceToken(data)
                        }
                    }
                } catch (error) {
                    console.log("on message error", error)
                }
            };
      
            socket.onclose = () => {
              console.log('WebSocket FX disconnected');
            };
      
            socket.onerror = (error) => {
              console.error('WebSocket FX error:', error);
            };
          },
      [tradeOrder, tradeTx, selectedFx],
    );

    const onSetPriceToken = (data) => {
        const { symbol, bid } = data;
        //const value = symbol.replace("USD", "");
        console.log(symbol , "price ====>", bid);
        if (loadingFX && symbol === selectedFx?.symbol_exchange) {
            setLoadingFX(false);
        }
        setPriceFX((prev) => ({
            ...prev, 
            [symbol]: parseFloat(bid).toFixed(2)
        }));
    }

    const onSelectFx = (item) => {
        if (item?.symbol_exchange === selectedFx?.symbol_exchange) {
            return;
        }
        setSelectedFx(item);
    }

    return {
        priceFX,
        setPriceFX,
        onSelectFx,
        loadingFX,
        setLoadingFX
    }
}