import {
  Box, Toolbar, Avatar, Typography, Badge, Paper
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faCalendar, faEyeSlash, faCircleQuestion, faInfo } from "@fortawesome/free-solid-svg-icons";
import './account-overview.style.css'
import { styled } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts/LineChart';
import { dataset } from './constants'
import { DataGrid } from '@mui/x-data-grid';
import BreadcrumbsNav from "../Breadcrumbs"
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import api from '../../instance_api'
import api_key from '../../instance_api_key'
import { differenceInDays, parseISO } from 'date-fns';
import { TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tab, Tabs, MenuItem, IconButton } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  },
];

const paginationModel = { page: 0, pageSize: 5 };

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];



const AccountOverview = () => {

  const [acccountId, setAccountId] = useState("");
  const [account, setAccount] = useState(null);
  const navigate = useNavigate();

  // Get the location object
  const location = useLocation();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    let id = queryParams.get('id');
    if(!id) {
      id = sessionStorage.getItem('account');
    }
    GetAccountById(id);
   
  }, []);

  const GetAccountById = async (id) => {
    await api_key.get(`/api/account/get_v1_account_by_id_profile/${id}`)
    .then(response => {
      setAccount(response.data);
    })
    .catch(error => console.error(error));
  }

  const handlePayout = () => {
    navigate("/profile/payout?id=" + queryParams.get('id'));
  }

  //#region Profit target
  const profitTagetTax8 = (profit_target) => {
    return profit_target * (8/100);
  }
  //#endregion

  //#region  Daily loss left
  const [dailyLossLeft, setDailyLossLeft] = useState(0);
  useEffect(() => {
    const session_account_reload = sessionStorage.getItem('account');
    if (session_account_reload) {

      GetTradingPosition(session_account_reload);
    }
  }, []);
  const [trade_tx, setTradeTx] = useState([]);
  const GetTradingPosition = async (session_account_reload) => {
    const responsePosition = await api.get('/api/trading_tx/get_v1_trading_tx_by_account_id/' + session_account_reload + '/0');
    setTradeTx(responsePosition.data);
  }
  const fetchFundingRate = async () => {
    try {
        const response = await fetch('https://fapi.binance.com/fapi/v1/fundingRate?symbol=ETHUSDC');
        const data = await response.json();
        if (data && data.length > 0) {
            setFundingRate((parseFloat(data[0].fundingRate) * 100).toFixed(5));
        }
    } catch (error) {
        console.error('Lỗi khi lấy funding rate:', error);
    }
};
   useEffect(() => {
          // Gọi hàm fetch lần đầu và sau đó cứ mỗi 1 giờ gọi lại
          fetchFundingRate();
          const interval = setInterval(fetchFundingRate, 360000);
  
          // Hủy interval khi component bị hủy
          return () => clearInterval(interval);
      }, []);
    const [price, setPrice] = useState(0);
      const [btcPrice, setBtcPrice] = useState(0);
      const [bnbPrice, setBnbPrice] = useState(0);
      const [pepePrice, setPepePrice] = useState(0);
      const [xrpPrice, setXrpPrice] = useState(0);
      const [solPrice, setSolPrice] = useState(0);
      const [dogePrice, setDogePrice] = useState(0);
      const [fundingRate, setFundingRate] = useState(null);
      useEffect(() => {
          const pairs = ['btcusdt', 'ethusdt', 'bnbusdt', 'pepeusdt', 'dogeusdt', 'xrpusdt', 'solusdt'];
          const streams = pairs.map(pair => `${pair}@trade`).join('/');
          const wsUrl = `wss://stream.binance.com:9443/stream?streams=${streams}`;
  
          const wsAll = new WebSocket(wsUrl);
          wsAll.onmessage = (event) => {
              const parsedData = JSON.parse(event.data);
              const trade = parsedData.data;
              switch (trade.s) {
                  case 'ETHUSDT': { setPrice(parseFloat(trade.p).toFixed(2)); }; break;
                  case 'BTCUSDT': { setBtcPrice(parseFloat(trade.p).toFixed(2)); }; break;
                  case 'BNBUSDT': { setBnbPrice(parseFloat(trade.p).toFixed(2)); }; break;
                  case 'PEPEUSDT': { setPepePrice(parseFloat(trade.p).toFixed(2)); }; break;
                  case 'DOGEUSDT': { setDogePrice(parseFloat(trade.p).toFixed(2)); }; break;
                  case 'XRPUSDT': { setXrpPrice(parseFloat(trade.p).toFixed(2)); }; break;
                  case 'SOLUSDT': { setSolPrice(parseFloat(trade.p).toFixed(2)); }; break;
                  default: break;
              }
          };
  
          // Đóng WebSocket khi component bị hủy
          return () => {
              if (wsAll.readyState === WebSocket.OPEN) {
                  wsAll.close();
              }
          }
      }, []);
  const getPopupPrice = (token) => {
    if (token == null) {
        switch (sessionStorage.getItem("token")) {
            case "BTC": return Number(btcPrice);
            case "ETH": return Number(price);
            case "BNB": return Number(bnbPrice);
            case "SOL": return Number(solPrice);
            case "XRP": return Number(xrpPrice);
            case "DOGE": return Number(dogePrice);
            case "PEPE": return Number(pepePrice);
            default: return 0;
        }
    } else {
        switch (token) {
            case "BTC": return Number(btcPrice);
            case "ETH": return Number(price);
            case "BNB": return Number(bnbPrice);
            case "SOL": return Number(solPrice);
            case "XRP": return Number(xrpPrice);
            case "DOGE": return Number(dogePrice);
            case "PEPE": return Number(pepePrice);
            default: return 0;
        }
    }
}
  function parseDateString(dateString) {
    // Tách ngày, tháng, năm, giờ, phút, giây từ chuỗi định dạng dd/MM/yyyy HH:mm:ss
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    // Tạo đối tượng Date, lưu ý tháng - 1 vì tháng bắt đầu từ 0 trong Date
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }
  function calculateHoursBetweenDates(date1, date2) {
    const timeDiff = Math.abs(date2 - date1);
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));

    return hoursDiff;
  }
  const GetPnl = (item) => {
    const count_hour = calculateHoursBetweenDates(new Date(), parseDateString(item.tx_dt));
    if (item.transaction_type == 'Long') {
        const pnl_value = (Number(getPopupPrice(item.unit)) - item.entry_price) * (item.pay_amt / item.entry_price * item.leverage)
            - (item.pay_amt * item.leverage * 0.0002 * count_hour) - (item.pay_amt * item.leverage * Number(fundingRate / 100));
        return Number(pnl_value).toFixed(2);
    } else {
        const pnl_value = (item.entry_price - Number(getPopupPrice(item.unit))) * (item.pay_amt / item.entry_price * item.leverage)
            - (item.pay_amt * item.leverage * 0.0002 * count_hour) - (item.pay_amt * item.leverage * Number(fundingRate / 100));
        return Number(pnl_value).toFixed(2);
    }

  }
  useEffect(() => {
    if (trade_tx && trade_tx.length > 0) {
        let sum_pnl = 0;
        trade_tx.forEach(element => {
          sum_pnl += Number(GetPnl(element));
      });
        if (sum_pnl < 0) {
          setDailyLossLeft((Number(sum_pnl) * -1).toFixed(2));
      }
    }
  }, []);
  //#endregion

  //#region Inactive Days
  useEffect(() => {
    const session_account_reload = sessionStorage.getItem('account');
    if (session_account_reload) {
      GetTradingHistory(session_account_reload);
    }
  }, []);
  const [trade_tx_history, setTradeTxHistory] = useState([]);
  const GetTradingHistory = async (session_account_reload) => {
    const responseHistory = await api.get('/api/trading_tx/get_v1_trading_tx_by_account_id/' + session_account_reload + '/2');
    setTradeTxHistory(responseHistory.data);
  }
  const [inactiveDays, setInactiveDays] = useState(0);
  useEffect(() => {
    if (trade_tx_history.length > 0) {
      // Lấy ngày cuối cùng trong danh sách
      const lastTxDate = trade_tx_history[trade_tx_history.length - 1].tx_dt;
  
      // Chuyển đổi định dạng từ "28/12/2024 13:53:24" sang "2024-12-28T13:53:24"
      const formattedDate = lastTxDate
        .replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')
        .replace(' ', 'T');
  
      const today = new Date(); // Ngày hiện tại
  
      // Chuyển đổi ngày cuối cùng sang định dạng Date
      const parsedLastDate = parseISO(formattedDate);
  
      // Tính số ngày không hoạt động
      const daysInactive = differenceInDays(today, parsedLastDate);
      setInactiveDays(daysInactive);
    }
  }, [trade_tx_history]);
  //#endregion

  //#region Trading Overview
  const [trading_overview, setTradingOverview] = useState([]);
  useEffect(() => {
    const session_account_reload = sessionStorage.getItem('account');
    if (session_account_reload) {
      GetTradingOverview(session_account_reload);
    }
  }, []);
  const GetTradingOverview = async (session_account_reload) => {
    const responseHistory = await api.get('/api/account/get_v1_account_overview/' + session_account_reload);
    setTradingOverview(responseHistory.data);
  }
  //#endregion

  //#region Refreshing in
  const [timeLeft, setTimeLeft] = useState(5 * 60); // 5 phút = 300 giây

  useEffect(() => {
    // Thiết lập bộ đếm ngược
    const countdown = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(countdown); // Dừng khi đếm ngược về 0
          window.location.reload(); // Reload trang
        }
        return prev - 1;
      });
    }, 1000);

    // Cleanup interval khi component bị unmount
    return () => clearInterval(countdown);
  }, []);

  // Chuyển đổi giây thành phút và giây
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  //#endregion

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
      <Toolbar />
      <BreadcrumbsNav />
      <br></br>
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12, md: 5, lg: 3 }}>
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item size={{ xs: 5, md: 3, lg: 3 }}>
                <Badge color="success" variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}>
                  <Avatar alt="avatar" src={`${process.env.PUBLIC_URL}/images/avatar.png`} />
                </Badge>
              </Grid>
              <Grid item size={{ xs: 7, md: 9, lg: 9 }}>
                <Grid container spacing={3}>
                  <Grid item size={{ xs: 5, md: 5, lg: 5 }}>
                    <div><b style={{ fontSize: 'larger' }}>Hi, Sam</b></div>
                  </Grid>
                  <Grid item size={{ xs: 7, md: 7, lg: 7 }}>
                    <div className="account-status">{account?.account_type}</div>
                  </Grid>
                </Grid>
                <div>You have an Evaluation Account</div>
              </Grid>
            </Grid>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <div>
                <img src={`${process.env.PUBLIC_URL}/images/Vector1.png`} />
                &nbsp;
                Initial Balance: $ {account?.balance}
              </div>
              <div>
                <img src={`${process.env.PUBLIC_URL}/images/Vector1.png`} />
                &nbsp;
                Plan Type: Free Trial | 6K
              </div>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px', padding: '15px', }}>
              <Box sx={{ width: '100%' }}>
                <img src={`${process.env.PUBLIC_URL}/images/Vector2.png`} />
                &nbsp;
                Trading Cycle Details
              </Box>
              <Box sx={{ width: '100%' }}>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Start date</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.created}&nbsp;<FontAwesomeIcon icon={faCalendar} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>End date</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.end_date}&nbsp;<FontAwesomeIcon icon={faCalendar} /></div>
                </div>
              </Box>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px', padding: '15px', }}>
              <Typography variant="h5" gutterBottom>
                Account Details
              </Typography>
              <Box sx={{ width: '100%' }}>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Login</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.user_name}&nbsp;<FontAwesomeIcon icon={faClone} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Master Pass</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.password}&nbsp;<FontAwesomeIcon icon={faEyeSlash} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Metamask Add</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>hkeekhr...&nbsp;<FontAwesomeIcon icon={faClone} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Server</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>WEB3 TRADE</div>
                </div>
                {/* <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Refund fee gas</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>30%</div>
                </div> */}
                <div>
                  <Button variant="outlined" onClick={handlePayout}>Payout</Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item size={{ xs: 12, md: 7, lg: 9 }} >
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, width: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Stats
            </Typography>
            <Box sx={{ width: '100%' }}>
              <div style={{ display: 'flex' }}>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Success.png`} />
                  </div>
                  {/* <div>Balance</div> */}
                  {/* <div><h2>$ {account?.balance}</h2></div> */}
                  <i style={{ fontWeight: "600", fontSize: "12px"}}>3 days with 0.5% profit</i>
                </div>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Circle5.png`} />
                    <FontAwesomeIcon/>
                  </div>
                  {/* <div>Profit target</div>
                  <div><h2>{profitTagetTax8(account?.balance)}</h2></div> */}
                  <i style={{ fontWeight: "600", fontSize: "12px"}}>Profit 8%/5%</i>
                </div>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Circle5.png`} />
                  </div>
                  {/* <div>Daily loss left</div>
                  <div><h2>{dailyLossLeft}</h2></div> */}
                  <i style={{ fontWeight: "600", fontSize: "12px"}}>Daily drawdown limit 5%</i>
                </div>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Success.png`} />
                  </div>
                  {/* <div>Payout {">"} 10%</div>
                  <div><h2>{account?.payout}</h2></div> */}
                  <i style={{ fontWeight: "600", fontSize: "12px"}}>Total drawdown limit 10%</i>
                </div>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Circle5.png`} />
                    <FontAwesomeIcon  />
                  </div>
                  {/* <div>Inactive Days</div>
                  <div><h2>{inactiveDays}</h2></div> */}
                  <i style={{ fontWeight: "600", fontSize: "12px"}}>Single profitable trade should account for mỏe than 40% of total profit</i>
                </div>
              </div>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <Typography variant="h5" gutterBottom>
                Trading Overview
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              Refreshing in {formatTime(timeLeft)}
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Order number&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      ${trading_overview.order_number}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Balance&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      ${trading_overview.balance}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Profitability
                    </div>
                    <div style={{ width: '50%' }}>
                      ${trading_overview.profitability}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Avg. Winning Trade&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      ${trading_overview.avg_winning_trade}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Avg. Losing Trade&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      ${trading_overview.avg_losing_trade}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Trades&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                    {trading_overview.trades}
                    </div>
                  </div>
                </Grid>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Volume&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                    {trading_overview.lots}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Avg. RRR&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                    {trading_overview.avg_rrr}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Win Rate
                    </div>
                    <div style={{ width: '50%' }}>
                    {trading_overview.win_rate}%
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Loss Rate
                    </div>
                    <div style={{ width: '50%' }}>
                    {trading_overview.loss_rate}%
                    </div>
                  </div>
                  {/* <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Profit Factor
                    </div>
                    <div style={{ width: '50%' }}>
                    {trading_overview.profit_factor}
                    </div>
                  </div> */}
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Best Trade
                    </div>
                    <div style={{ width: '50%' }}>
                      ${trading_overview.best_trade}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <Typography variant="h5" gutterBottom>
                Trading history
              </Typography>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TableContainer sx={{ padding: '0px', maxHeight: 500, overflow: 'auto' }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Order</TableCell>
                                <TableCell>Symbol</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell align="right">Net Value</TableCell>
                                <TableCell align="right">Volume</TableCell>
                                <TableCell align="right">Entry Price</TableCell>
                                <TableCell align="right">Mark Price</TableCell>
                                <TableCell align="right">PnL</TableCell>
                                <TableCell align="right">Exit Price</TableCell>
                                <TableCell align="right">Tx Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trade_tx_history.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell> {row.stt} </TableCell>
                                    <TableCell> {row.unit}/USDX </TableCell>
                                    <TableCell> {row.transaction_type} </TableCell>
                                    <TableCell> {row.trading_type} </TableCell>
                                    <TableCell align="right">{row.pay_amt}</TableCell>
                                    <TableCell align="right">{row.size}</TableCell>
                                    <TableCell align="right">{row.entry_price}</TableCell>
                                    <TableCell align="right">{row.mark_price}</TableCell>
                                    <TableCell align="right"><span style={{ color: row.pnl_amt < 0 ? 'red' : 'green' }}>{row.pnl_amt}</span></TableCell>
                                    <TableCell align="right">{row.exit_price}</TableCell>
                                    <TableCell align="right">{row.tx_dt}</TableCell>
                                </TableRow>
                            ))}
                            {(!trade_tx_history || trade_tx_history.length == 0) &&
                                <TableRow
                                    key='none'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell colSpan={10}> No data </TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
          </Box>
          {/* <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.85)', 
              zIndex: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '48px', 
              fontWeight: '900', 
              color: '#000',
              textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)',
              letterSpacing: '2px', 
            }}
          >
            Coming Soon
          </div> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountOverview;
